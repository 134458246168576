import "./Common/NavigationBar.css";
import $ from 'jquery';
import React from "react";

class NavigationBar extends React.Component{
    constructor(props){
        super(props);
        this.scrollToElement = this.scrollToElement.bind(this);
    }

    scrollToElement(elementId){
        $([document.documentElement, document.body]).animate({
            scrollTop: $(elementId).offset().top+10
        }, 500);
        $('#navigation-bar').toggleClass('open');
    }

    render() {
    
        return(
            <nav id="navigation-bar" className="container">
                <div className="row">
                    <div className="col">
                        <h1>RahulGPutcha</h1>
                        <div id="nav-toggle">
                            <div id="nav-links">
                                <ul>
                                    <li><a onClick={(e)=>{e.preventDefault();this.scrollToElement("#home");}} href="#home">Home</a></li>
                                    <li><a onClick={(e)=>{e.preventDefault();this.scrollToElement("#about");}} href="#about">About</a></li>
                                    <li><a onClick={(e)=>{e.preventDefault();this.scrollToElement("#my-creations");}} href="#my-creations">My Creations</a></li>
                                    <li><a onClick={(e)=>{e.preventDefault();this.scrollToElement("#my-certifications");}} href="#my-certifications">My Certifications</a></li>
                                    <li><a onClick={(e)=>{e.preventDefault();this.scrollToElement("#contact-me");}} href="#contact-me">Contact Me</a></li>
                                </ul>
                            </div>
                            <div style={{float: "right", paddingTop: "5px"}}>
                                <button onClick={(e)=>{$('#navigation-bar').toggleClass('open');}} id="nav-expand"><i className="fas fa-chevron-right"></i></button>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
            </nav>
        );
    }
}

export default NavigationBar;