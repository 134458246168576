const ProjectSlider = (props)=>{
    return (
        <div className="row project-slider">
            <div className="col">
                <div className="project-container p-2">

                    {
                        (props.projectData)?(
                            props.projectData.map((e,i)=>{
                                return(
                                    <div key={`project-`+i} className='span'>
                                        <div className='py-4 px-3'>
                                            <h3 className="text-white h5 ">
                                                {e.title}
                                            </h3>
                                            <div className='text-end pt-4 pe-3'>
                                                <button onClick={(e)=>{props.setProject(i)}}>View</button>
                                            </div>
                                        </div>
                                    </div>
                                );
                                
                            })
                        ):""
                        
                    }
                    
                    



                    {/* GitHubProfile */}
                    <div className='span github-profile'>
                        <div className='py-4 px-3'>
                            <h3 className="h5 ">
                                My GitHub
                            </h3>
                            <div className='text-center pt-4'>
                                <button onClick={(e)=>{window.location.href='https://github.com/RPG-coder';}}><i className="fab fa-github h6"></i> View Github Profile</button>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default ProjectSlider;