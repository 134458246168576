import "./Common/Footer.css";
import {Link} from 'react-router-dom';
const Footer = () => {
    return(
        <footer id="footer" className="container-fluid p-5 text-center">
            <div className="row">
                <div className="col">
                    &copy; Copyright | Under <strong>BSD 3-Clause License</strong>, to know more see the <Link to="/LICENSE">Terms and Conditions</Link>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <small>Designed by Rahul Gautham Putcha <br />(RPG~RAH | RPG-coder)</small> 
                    <div className="mt-1 h3"><i className="far fa-grin-beam"></i></div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;