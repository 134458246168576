const CertificationDescription = (props)=>{
    return(
        <div className="row pt-5">
            <div style={{textAlign:"justify"}} className="col">
                <h3>Description</h3>
                <h4>{props.certification.title}</h4>
                <p>
                    {props.certification.description}
                </p>
                <div className="know-more-btn w-100 text-end pe-4">
                    {(props.certification.link!=="#"&&props.certification.link)?<a className="text-white h6" href={props.certification.link}>See Credentials <i className="fas fa-chevron-right"></i></a>:""}
                </div>
            </div>
        </div>
    );
}

export default CertificationDescription;