import React from 'react';
import pes_logo from './About/pes_logo.png';
import njit_logo from './About/NJIT.png';
import './About/About.css';
import $ from 'jquery';
import 'animate.css';
import DP from './About/DP.jpg';

class About extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            hasAnimationPlayed : false,
            hasQuoteAnimationPlayed : false
        };

    }

    componentDidMount(){
        $('#about-content').css('opacity', 0);
        $('#about-education-region').css('opacity', 0);
        $('#about-social').css('opacity', 0);
        $('#quote1>#quote-content>*').css('opacity', 0);

        $(document).on('scroll', ()=>{
            
            if(!this.state.hasAnimationPlayed && ($(document).scrollTop() > $("#about").offset().top-($(window).height()/2)) ){

                $('#about-content').css('opacity', 1).addClass("animate__animated animate__fadeInLeft");
                $('#about-education-region').css('opacity', 1).addClass("animate__animated animate__fadeInUp");
                $('#about-social').css('opacity', 1).addClass("animate__animated animate__fadeInRight");

                this.setState({
                    hasAnimationPlayed : true
                });
            }
            if(!this.state.hasQuoteAnimationPlayed &&  ($(document).scrollTop() > $("#quote1").offset().top-($(window).height()/2))){

                $('#quote1>#quote-content>*').css('opacity', 1).addClass("animate__animated animate__fadeIn");

                this.setState({
                    hasQuoteAnimationPlayed : true
                });
            }
        });
    }
    render(){
        return (
            <section id="about" className="container-fluid">
                <div className="row">
                    <div className="col-1 pagebar-region">
                    </div>
                    <div className="col-10 col-lg-11 col-xl-11 col-xxl-10">
                        <div className="row">
                            
                            {/* AboutContent */}
                            <div id="about-content" className="col-12 col-lg-9 py-5">
                                <div className="row py-5">
    
                                    {/* AboutImage */}
                                    <div id="about-image" className="text-center text-lg-left col-12 col-lg-4 col-xl-5 px-5 mx-auto">
                                        <div className='bg-dark h-100 text-center' style={{borderRadius:"20px", overflow:"hidden"}}>
                                            <img src={DP} alt="Rahul Gautham Putcha"/>
                                        </div>
                                    </div>
    
                                    {/* AboutContent */}
                                    <div  className="col-12 col-lg-12 col-xl-7 px-2 px-lg-5 px-lg-1 pt-5">
                                        <h2>Who am I?</h2>
                                        <div style={{textAlign:"justify"}} className="p-3">
                                            <p>
                                                I am a Software Developer Pro and a tech enthusiast. I love
                                                facing challenging problems optimistically, and try to find intuitive
                                                ways to design tech solutions for making life easier and much better
                                                for people.
                                            </p>
                                            <p>
                                                I love coding and developing standalone applications &amp; distributed 
                                                web applications that are running on Cloud.
                                            </p>
                                            <p>
                                                <strong>My Hobbies:</strong> <br/>
                                                Web designing, Coding (Hackerrank or Leetcode), Building Apps, 
                                                Collecting Certifications, Reading Books ( and Reading Mangas upon 
                                                sparing an hour on Saturdays!)
                                            </p>
                                            <p>
                                                <strong>Overall:</strong> <br/>
                                                I am a Tech Geek and a Tech Optimist
                                            </p>
    
                                            <div className="w-100 text-end know-more-btn">
                                                <a href='https://www.linkedin.com/in/rahul-putcha/'>Know more about me <i className="fas fa-chevron-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            {/* AboutEducation */}
                            <div id="about-education-region" style={{backgroundColor:"#dfe6e9"}} className="col-12 col-lg-3 text-dark">
                                <div className="row h-100">
                                    <div id="about-education"  className="col-11">
                                        <div className="row title pt-5">
                                            <div className="col">
                                                <h3>My Education</h3>
                                            </div>
                                        </div>
                                        <div className="row pt-3">
                                            <div className="col p-2 pt-4 ps-3 text-center">
                                                <img src={pes_logo} alt="PES University logo" />
                                                <div className='pt-3'>
                                                    <p style={{margin:0,padding:0}}>Bachelors in Information Science</p>
                                                    <p style={{margin:0,padding:0}}>Aug 2015 - Jul 2019</p>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div className="row py-5">
                                            <div className="col p-2 pt-4 ps-3 text-center">
                                                <img src={njit_logo} alt="NJIT logo" />
                                                <div className='pt-3'>
                                                    <p style={{margin:0,padding:0}}>Master in Computer Science</p>
                                                    <p style={{margin:0,padding:0}}>Jan 2020 - Dec 2021</p>
                                                    <p style={{margin:0,padding:0}}>GPA: 3.5</p>
                                                </div>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>
                            </div>
    
    
                        </div>
                    </div>
                    
                    <div id="about-social" style={{backgroundColor:"#dfe6e9"}} className="col-2 col-lg-1">
                        <div className="row h-100">
                            <div id="about-social-region" className="w-100 col-12 col-md-10 bg-danger text-center">
                                <div>
                                    <ul className="p-0 pt-5 text-center" style={{width:"100%"}}> Social<br/>Links
                                        <li><a href="https://www.linkedin.com/in/rahul-putcha/"><i className="fab fa-linkedin-in"></i></a></li>
                                        <li><a href="https://github.com/RPG-coder/"><i className="fab fa-github-alt"></i></a></li>
                                        <li><a href="https://www.facebook.com/rahul.putcha"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="https://www.instagram.com/raheat_rpg/"><i className="fab fa-instagram"></i></a></li>
                                        <li><a href="https://www.hackerrank.com/rahulgautham95"><i className="fab fa-hackerrank"></i></a></li>
                                        <li><a href="mailto:rahulgautham95@gmail.com"><i className="fas fa-envelope"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="quote1" className="row">
                    <div className="col-1 pagebar-region"></div>
                    <div id="quote-content" className="col bg-black pt-5 ps-3 ps-lg-5 pb-3">
                        <h3 className="h3">My Favourite quote</h3>
                        <span className="h3"><i className="fas fa-quote-left"></i></span>
                        <p className="text-center h5">
                            People may forget what you said, People may forget what you did,<br className='display-md-none' />
                            but they will always remember what you made them feel.
                        </p>
                        <p className="h5 pe-1 pe-lg-5 text-end w-100">
                            <em className="pe-1 pe-lg-5">- Maya Angelou</em>
                        </p>
                    </div>
                </div>
            </section>
        );
    }
    
}

export default About;
