import React from 'react';
import './MyCreations/MyCreations.css';
import ProjectPresentation from './MyCreations/ProjectPresentation';
import ProjectSlider from './MyCreations/ProjectSlider';
import $ from 'jquery';

const projectArray = [
    {
      title: "Practice Management Portal",
      projectLink:"https://github.com/RPG-coder/practice-management-portal",
      videoLink:"https://www.youtube.com/embed/iqD0TMaFbDQ",
      description:"One of the project that I have presented as part of course curriculum for CS673 - Software Designs and Production Methodology at NJIT, Newark. Practice Management Portal is one part of 8 projects conducted by the 8 agile teams. Specifically, the Practice Management Portal is an application involved with the dealing of the practice management, and is used by the internal employee of a hospital organization for easing their patient care activities. This product solve the problem of managing and displaying a simple view of the patient records, displaying the patient's visit details to doctors and establish a messaging system & interface for the doctors to communicate with their patients (doctor's side-communication only). Along with this the application is adjusted to display the content that only relevant to the type of user who is accessing the system, namely doctor, nurse or practice manager. This app is currently hosted and can be accessed on Heroku."
    },{
      title: "Playing Atari with Transfer Learning",
      projectLink:"https://github.com/RPG-coder/atari-transfer-learning",
      videoLink:"https://www.youtube.com/embed/Od_PN2Osv7Q",
      description:"Part of CS677 - Deep Learning at NJIT, Newark. This project reuses the idea of Playing Atari Games with Reinforcement Learning presented by Google Deepmind. Playing Atari with Transfer Learning is one of my interesting projects that reuses the knowledge of a model thats been trained to play a game perfectly for another similar game of higher complexity. The idea is not to train the model for the second game of higher complexity from scratch but instead retrain the current model using any prevous model (h5 file) thats trained to resolve a part of the problem. This makes the training process efficient and make fast convergence to the solution."
    },{
      title: "Shopping Recommendation System",
      projectLink:"https://github.com/RPG-coder/apriori-algorithm-php",
      videoLink:"https://www.youtube.com/embed/PI7f-Q6GSJ0",
      description:"Part of CS634 - Data Mining at NJIT, Newark. The Shopping Recommendation system is an application that automatically recommends the user about products that they can buy based on their previously performed shopping transaction. This approach uses the most famously known Apriori Algorithm to provide an efficient approach thats 1000 time faster than any bruteforce algorithm. The application works similar to the recommendation system thats used in any modern e-commerce application like Amazon, Etsy, Ebay etc."
    },{
      title: "Tic Tac Toe",
      projectLink:"https://github.com/RPG-coder/TicTacToe-Game",
      videoLink:"https://www.youtube.com/embed/PWNjz7Lr92I",
      description:"Part of my undergraduate projects in Artificial Intelligence at PES University - South Campus. Tic Tac Toe is a classic game of crosses and circles involving two player choosing either one of the shapes (circle & cross) to play a game of making alternate moves, in order to win by getting their first consecutive pattern. The consecutive pattern can be either on diagonal-wise, row-wise or column-wise. The additional feature that peek the interest of this project is the ability to make a computer play a game against human. The Artificial Intelligence algorithm used for this game makes real suprising plays that may even put many humans into thinking."
    },{
      title: "8 & 15-Puzzle",
      projectLink:"https://github.com/RPG-coder/8-puzzle-and-15-puzzle-Game",
      videoLink:"https://www.youtube.com/embed/tZ4fMroN_ss",
      description:"Part of my undergraduate project in Artificial Intelligence at PES University - South Compus. 15-Puzzle (or it's mini-version the 8-Puzzle) is a classic game of number tiles that are randomly arranged on a board. The player is tasked to finish the game by rearranging the tile to their original/proper sequence. The constraints involved in moving the tiles one at a time in the single empty tile region makes this game challenging and splits the tiles on the board into one of the two categories, namely playable and none playable. The game also applies an artificial intelligence algorithm that provides an additional feature for hinting the player about the next tile that may eventually lead to solving the puzzle."
    },{
      title: "On Cloud ML Classifier",
      projectLink:"https://github.com/RPG-coder/On-Cloud-Classifier",
      videoLink:"https://www.youtube.com/embed/Qr1P4dy0O5o",
      description:"Part of CS643-Cloud Computing at NJIT, Newark. On Cloud ML Classifier is a machine learning service application on cloud that trains and provide the trained model file on any supplied dataset. The problem solved by the model is only limited to Multi-Class problem (leaving the more complex version involving Multi-Lable problem aside). One of the ability of this application is the use of Spark ML providing a parallel processing and training of model with the advantage of AWS (Amazon Web Service) cloud computing."
    }
  ];

class MyCreations extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            activeProject: 0,
            hasAnimationPlayed: false
        }
        this.setActiveProject=this.setActiveProject.bind(this);
        
    }
    
    componentDidMount(){
      $('#my-creations h2>span').css('opacity',0);
      $('#my-creations .presentation').css('opacity',0);
      $('#my-creations .description').css('opacity',0);
      $("#my-creations .presentation>h3>span>span").css('opacity',0);
      $('#my-creations .project-container').animate({
        scrollLeft: $('#my-creations .project-slider').width()
      }, 0);


      $(document).on('scroll', ()=>{
            
        if(!this.state.hasAnimationPlayed && ($(document).scrollTop() > $("#my-creations").offset().top-($(window).height()/2)) ){

          $("#my-creations .presentation").css('opacity',1).addClass("animate__animated animate__slideInLeft");
          $("#my-creations .presentation>h3>span>span").css('opacity', 1).addClass("animate__animated animate__slideInRight animate__delay-1s");
          $("#my-creations .description").css('opacity',1).addClass("animate__animated animate__slideInRight");
          $('#my-creations h2>span').css('opacity',1).addClass('animate__animated animate__slideInLeft');
          $('#my-creations .project-container').animate({
            scrollLeft: 0
          }, 1000);
          this.setState({
              hasAnimationPlayed : true
          });
        }
      });
    }

    setActiveProject(index){  
      $(document).scrollTop($("#my-creations").offset().top);

      setTimeout(()=>{
        clearInterval();
        $("#my-creations .presentation").css('opacity', 0).removeClass("animate__animated animate__slideInLeft");
        $("#my-creations .description").css('opacity', 0).removeClass("animate__animated animate__slideInRight");
        $("#my-creations .presentation>h3>span>span").css('opacity', 0).removeClass("animate__animated animate__slideInRight animate__delay-1s");
        setTimeout(()=>{
          this.setState({
            activeProject: index
          });
          $("#my-creations .presentation").css('opacity',1).addClass("animate__animated animate__slideInLeft");
          $("#my-creations .description").css('opacity',1).addClass("animate__animated animate__slideInRight");
          $("#my-creations .presentation>h3>span>span").css('opacity', 1).addClass("animate__animated animate__slideInRight animate__delay-1s");
        }, 400);
      }, 400);
        
    }
    render(){
        return (
            <section id="my-creations" className="container-fluid text-dark">
                <div className="row">
                    <div className="col-1 pagebar-region">
                    </div>
                    <div className="col-12 col-xxl-11 bg-white p-1 p-sm-2 p-lg-5">
                        <div className="row title">
                            <h2 style={{overflow: "hidden"}} className="h3"><span style={{display:"inline-block"}}>My Creations</span></h2>
                        </div>
    
                        <ProjectPresentation projectObj={projectArray[this.state.activeProject]} />
                        <ProjectSlider projectData={projectArray} setProject={this.setActiveProject}/>
    
    
                    </div>
                </div>
            </section>
        );
    }
}

export default MyCreations;
