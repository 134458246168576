
const CertificationCards = (props)=>{
    //console.log(props.certificationObj)
    return(
        <div className="col-12 w-100 p-1 p-lg-3 pt-5 cert-container">
            {
                (props.certificationObj)?(
                    props.certificationObj.map((e,i)=>{
                        return(<div key={'certification-'+i} className={((props.isActive===i)?'cert-card active':'cert-card')}>
                            <div className="card-overlay">
                                <div className='p-5'>
                                    <p>{e.title}</p>
                                    <button className={(props.isActive===i)?"bg-white text-dark":""} onClick={(event)=>{props.setCertification(i)}}>{(props.isActive===i)?"Viewed":"View"}</button>
                                </div>
                            </div>
                            <div className='card-img'>
                                <img src={e.image} alt={e.title} />
                            </div>
                        </div>);
                    })
                ):""
            }
            
        </div>
    );
}

export default CertificationCards;