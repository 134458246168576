import Home from './components/Home';
import NavigationBar from './components/NavigationBar';
import './App.css';
import About from './components/About';
import MyCreations from './components/MyCreations';
import MyCertifications from './components/MyCertifications';
import ContactMe from './components/ContactMe';
import Footer from './components/Footer';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import License from './components/License';
import React from 'react';
import $ from 'jquery';

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {sectionIndex: 0, onScrollDisabled:false}
    this.onResize = this.onResize.bind(this);
    
  }
  componentDidMount(){

    $(document).scrollTop(0);
    this.setState({
      sectionIndex: 0,
      firstScroll: false,
      onScrollDisabled:false,
      sectionsIds:[
        '#home','#about','#my-creations','#my-certifications','#contact-me','footer'
      ],
      topPositions: [
        $('#home').position().top,$('#about').position().top, 
        $('#my-creations').position().top, $('#my-certifications').position().top, 
        $('#contact-me').position().top, $('footer').position().top
      ]
    });
    
    $(window).on('resize', ()=>{this.onResize()});
    $(document).on('scroll',()=>{
      
      if(this.state.firstScroll===false){
        this.setState({
          topPositions: [
            $('#home').position().top,$('#about').position().top, 
            $('#my-creations').position().top, $('#my-certifications').position().top, 
            $('#contact-me').position().top, $('footer').position().top
          ]
        });
        this.setState({
          firstScroll: true
        });
      }
      
      if(this.state.onScrollDisabled===false){
        //console.log("scrolled");
        if(0<=(this.state.sectionIndex-1) && $(document).scrollTop()<this.state.topPositions[this.state.sectionIndex]){
          this.setState({sectionIndex:this.state.sectionIndex-1}, ()=>{
            $('.pagebar-region>div>ul>li:nth-child('+(this.state.sectionIndex+2)+')').removeClass('visited');
          });
        }else if((this.state.sectionIndex+1)<this.state.topPositions.length && this.state.topPositions[this.state.sectionIndex+1]<$(document).scrollTop()){
          this.setState({sectionIndex:this.state.sectionIndex+1}, ()=>{
            $('.pagebar-region>div>ul>li:nth-child('+(this.state.sectionIndex+1)+')').addClass('visited');
          });
        }
      }
    });

    $('.pagebar-region>div>ul>li:first').addClass('visited');
  }
  onResize(){
    this.setState({topPositions: [$('#home').position().top,$('#about').position().top, $('#my-creations').position().top, $('#my-certifications').position().top, $('#contact-me').position().top, $('footer').position().top]});
  }

  render(){
    if($(window).width()>380 && $(window).height()>280){
      return (
        <div className="App">
          
          <Router>
            <Switch>
              <Route exact path="/">
                <NavigationBar/>
                <Home/>
                <About/>
                <MyCreations/>
                <div className="hr-white"></div>
                <MyCertifications/>
                <div className="hr-white"></div>
                <ContactMe/>
  
                <div className='container-fluid bg-black'>
                    <div id="quote2" className="row">
                        <div className="col-1 pagebar-region"></div>
                        <div className="col p-2 py-5 pt-lg-5 ps-lg-5 pb-lg-3">
                            <div className="h3 ps-4"><i className="fas fa-quote-left"></i></div>
                            <p className="text-center h5">
                                Hoping to get to know you soon.
                            </p>
                            <p className="h5 pe-1 pe-lg-5 text-end w-100">
                                <em className="pe-1 pe-lg-5">- Rahul Gautham Putcha</em>
                            </p>
                        </div>
                    </div>
                </div>
  
                <Footer/>
                <button onClick={(e)=>{

                  this.setState({sectionIndex:0, onScrollDisabled:true}, ()=>{
                    console.log('clicked when', this.state.sectionIndex);
                    $([document.documentElement, document.body]).animate({
                      scrollTop: this.state.topPositions[0]
                    }, 300, ()=>{
                      setTimeout(()=>{ 
                        this.setState({onScrollDisabled:false}) 
                        $('.pagebar-region>div>ul>li').removeClass('visited');
                        $('.pagebar-region>div>ul>li:first').addClass('visited');
                      }, 1000);
                    })
                  });

                }} className="abs-btn top-btn"><i className="fas fa-chevron-up"></i> <small>Top</small></button>
                <button onClick={(e)=>{
                  if((this.state.sectionIndex+1)<this.state.topPositions.length){

                    this.setState({sectionIndex:this.state.sectionIndex+1, onScrollDisabled:true}, ()=>{
                      //console.log('clicked when', this.state.sectionIndex);
                      $([document.documentElement, document.body]).animate({
                        scrollTop: this.state.topPositions[this.state.sectionIndex]+10
                      }, 300);
                    });
                    //$('.abs-btn').prop('disabled', true);
                    setTimeout(()=>{ 
                      this.setState({onScrollDisabled:false},()=>{/*$('.abs-btn').prop('disabled', false);*/}) 
                      $('.pagebar-region>div>ul>li:nth-child('+(this.state.sectionIndex+1)+')').addClass('visited');
                    }, 1000);
                  }
                }}className="abs-btn scrolldown-btn"><small>Scroll Down</small><i className="fas fa-chevron-down"></i></button>
              </Route>
              <Route path="/LICENSE" component={License}/>
            </Switch>
          </Router>
        </div>
      );
    }else if($(window).height()<280){
      return(
        <div id="block-screen" className='p-4'>
          <p style={{textAlign:"justify"}}>
            This App is suited better for screen height greater than 280 px.
            This constraint is kept for better experience while using this website.
          </p>
          <div className='text-end'>
              <em>- Rahul Gautham Putcha</em>
          </div>
          <div className='text-center p-5 text-white'>
            Goto My <br/><a style={{display:"inline-block", textDecoration:"none", margin: "10px", padding: "10px 30px", backgroundColor:"#006192", color: "white"}}href="https://www.linkedin.com/in/rahul-putcha/"> Linked <i className="fab fa-linkedin-in"></i></a>
          </div>

          <div className='text-center pt-4'>
            <small><em>Hint: If possible, please try on using this App by changing the screen orientation to landscape or potrait mode.</em></small>
          </div>

        </div>
      );
    }else{
      return(
        <div id="block-screen" className='p-4'>
          <p style={{textAlign:"justify"}}>
            This App is suited better for screen width greater than 380 px.
            This constraint is kept for better experience while using this website.
          </p>
          <div className='text-end'>
              <em>- Rahul Gautham Putcha</em>
          </div>
          <div className='text-center p-5 text-white'>
            Goto My <br/><a style={{display:"inline-block", textDecoration:"none", margin: "10px", padding: "10px 30px", backgroundColor:"#006192", color: "white"}}href="https://www.linkedin.com/in/rahul-putcha/"> Linked <i className="fab fa-linkedin-in"></i></a>
          </div>

          <div className='text-center pt-4'>
            <small><em>Hint: If possible, please try on using this App by changing the screen orientation to landscape or potrait mode.</em></small>
          </div>

        </div>
      );
    }
  }
  
}

export default App;
