const ProjectPresentation = (props)=>{
    return (
        <div className="row project-row">
            <div className="col-12 col-lg p-4 presentation">
                <h3 className="h4">
                    <span style={{overflow:"hidden", display:"inline-block"}}>
                        <span style={{display:"inline-block"}}>
                            {props.projectObj.title}
                        </span>
                    </span>
                </h3>
                <div className="content py-5 text-center">
                    <iframe title={props.projectObj.title} src={props.projectObj.videoLink} />
                </div>
            </div>
            <div className="col-12 col-lg p-3 description">
                <h4 className="h5">Description</h4>
                <p className="ps-4" style={{textAlign: "justify"}}>
                    {props.projectObj.description}
                </p>
                <div className="text-end know-more-btn">
                    <a href={props.projectObj.projectLink}>Know more about this Project <i className="fas fa-chevron-right"></i></a>
                </div>
            </div>
        </div>
    );
}

export default ProjectPresentation;