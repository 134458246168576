import React from 'react';
import $ from 'jquery';
import CertificationCards from './MyCertifications/CertificationCards';
import CertificationDescription from './MyCertifications/CertificationDescription';
import './MyCertifications/MyCertifications.css';
import tensorflow1Image from "./MyCertifications/coursera_tensorflow-ai-ml-dl.png";
import hackerrank1Image from "./MyCertifications/hankerrank_problem-solving-basic.png";
import nareshIT from "./MyCertifications/NareshIT.png";
import unitedBusinessInternship from "./MyCertifications/unitedBusiness_Internship.png";
import microsoft_DP900 from "./MyCertifications/microsoft_DP900.png";
import microsoft_AI900 from "./MyCertifications/microsoft_AI900.png";
import microsoft_AZ900 from "./MyCertifications/microsoft_AZ900.png";
import amazon_CP0 from "./MyCertifications/amazon_CloudPractioner.png";

const certificationData = [
    {
        title: "AWS Cloud Practioner",
        description: "AWS Cloud Practioner is one of the foundational level certification that tests the most important services and concepts underlying AWS. It is critical for any cloud enthusiast who want to start their development on AWS cloud. During this certification I have learnt the best practices on AWS and focus towards the development on certain AWS services, namely the AWS Lambda, AWS EC2, AWS Elastic Beanstalk, AWS RDS, AWS EMR and many more. The test in not only limited to services but also cover the basic cloud concepts and security pertaining the AWS cloud.",
        image: amazon_CP0,
        link: "https://www.credly.com/badges/7a80cd60-a40f-4e46-bb83-a68be143177e?source=linked_in_profile"
    },{
        title: "Microsoft Azure AZ-900",
        description: "Microsoft Azure AZ-900 is one of the foundational level certification that tests the most important services and concepts underlying Azure cloud. It is critical for any cloud enthusiast who want to start their development on Azure cloud. During this certification I have learnt the best practices on Azure and focus towards the development on certain Azure services, namely the Azure Function Apps, AWS VMs and App Services, Azure SQL Database, Azure Database for MySQL, common Azure Machine Learning applications and many more. The test in not only limited to services but also cover the basic cloud concepts and security pertaining the Azure cloud.",
        image: microsoft_AZ900,
        link: "https://www.credly.com/badges/1079a0f9-8759-48f7-8cb6-6c539e9cbdf0?source=linked_in_profile"
    },{
        title: "Microsoft Azure AI-900",
        description: "Microsoft Azure AI-900 is a foundational level certification that tests the most important services and concepts underlying Machine Learning & Data science on Azure cloud. Data science and Machine Learning are very critical part any business as it can be used to find hidden insights from any business data when used with right knowledge and resources. During this certification I have learnt the best practices for data science on Azure and focus towards the development on certain Azure services for Machine Learning, namely the Azure ML, AWS Cognitive Services. The test in not only limited to services but also cover the basic Data science & machine learning concepts and key responsibilities when choosing the right data without any bias in collecting it for a task in general.",
        image: microsoft_AI900,
        link: "https://www.credly.com/badges/7f3f9de8-b3af-471c-beb4-c10ad863f6cf?source=linked_in_profile"
    },{
        title: "Microsoft Azure DP-900",
        description: "Microsoft Azure DP-900 is a foundational level certification that tests the most important services and concepts underlying Data Architecture and management on Azure cloud. Like Data science and Machine Learning, Data is a very critical part any business as it provide a way to gain necessary insight for growing any business. The main goal of DP-900 is to test whether the candidate is capable of storing & securing the data on cloud and picturing how the business critical data flowing between various applications. During this certification I have learnt the best practices that a data architect should follow on Azure and focused towards the development on certain Azure services for Data Architecture, namely the Azure Databases, AWS Storage (Blob,Queue) and Security & access control on data on Azure. The test in not only limited to services but also cover the basic Relational Database & NoSQL concepts that are important for any developer to make business decision on designing a data architecture designs on applications.",
        image: microsoft_DP900,
        link: "https://www.credly.com/badges/1079a0f9-8759-48f7-8cb6-6c539e9cbdf0?source=linked_in_profile"
    },{
        title: "United Business Internship",
        description: "A certification to show case my work that was demonstrated at United Business in the year 2019. The work involved the Fullstack developer role where I was tasked to improve the previously running website at United Business in a short span of 2-3 month duration. The website is used by the United Business's marketing team to demonstrate their company detail, blogs, achievement, recruitment and their popular services.",
        image:  unitedBusinessInternship,
        link: "https://www.unitedbusiness.co.in/landing"
    },{
        title: "HackerRank Problem Solving",
        description: "This is a certification to showcase my capability of solving basic-level problems on Hackerrank platform.",
        image: hackerrank1Image,
        link: "https://www.hackerrank.com/certificates/777c4720b6b4"
    },{
        title: "Tensorflow for AI, ML & DL",
        description: "The certificate from a great course on Coursera, featuring the Google's Tensorflow Keras. This course has given me a strong knowledge about ML background and practical knowledge of solving problem on data science hands-on during the coursework.",
        image: tensorflow1Image,
        link: "https://www.coursera.org/account/accomplishments/verify/T3EGYU3T7KP4"
    },{
        title: "Naresh IT C/C++",
        description: "One of my initial programming certification which showcases the knowledge on C/C++. This certification has really helped me to step a foot in the world of computer science. The experience that I have gained through out my career can be related in all way with the knowledge I that was gained from this certification.",
        image: nareshIT,
        link: "#"
    }
];

class MyCertifications extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            activeCertification: 0
        };
        this.setActiveCertification=this.setActiveCertification.bind(this);
    }

    componentDidMount(){
        $('#my-certifications h2>span>span').css('opacity',0);
        $('#my-certifications .cert-container').animate({
            scrollLeft: $('#my-creations .project-slider').width()
        }, 0);

        $(document).on('scroll', ()=>{
            
            if(!this.state.hasAnimationPlayed && ($(document).scrollTop() > $("#my-certifications").offset().top-($(window).height()/2)) ){
  
              $("#my-certifications h2>span>span").css('opacity',1).addClass("animate__animated animate__slideInLeft");
              $('#my-certifications .cert-container').animate({
                scrollLeft: 0
              }, 1000);
              this.setState({
                  hasAnimationPlayed : true
              });
            }
        });
    }

    setActiveCertification(index){
        this.setState({
            activeCertification: index
        });
    }

    render(){
        return (
            <section id="my-certifications" className="container-fluid text-white p-1 p-md-5">
                <div className="row pt-5">
                    <div className="col-1 pagebar-region">
                    </div>
                    <div className="col-12 col-xxl-11 p-5">
                        
                        <div className="row">
                            <div className="col">
                                <h2>
                                    <span style={{display:"inline-block", overflow: 'hidden'}}>
                                        <span style={{display:"inline-block"}}>
                                            My Achievements
                                        </span>    
                                    </span>
                                </h2>
                            </div>
                        </div>
    
                        <div className="row pt-5">
                            <CertificationCards isActive={this.state.activeCertification} certificationObj={certificationData} setCertification={(i)=>{this.setActiveCertification(i)}}/>
                            <CertificationDescription certification={certificationData[this.state.activeCertification]}/>
                        </div>
    
                    </div>
                    
                </div>
            </section>
        );
    }
}

export default MyCertifications;
