import React from 'react';
import './ContactMe/ContactMe.css';
import $ from 'jquery';
import axios from 'axios';

class ContactMe extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            hasAnimationPlayed : false
        };
        this.sendMail = this.sendMail.bind(this);
    }

    sendMail(event){
        event.preventDefault(); 
        const request = {
            name: event.target.contact_name.value,
            email: event.target.contact_email.value,
            company: event.target.contact_company.value,
            contact: event.target.contact_number.value,
            description: event.target.contact_description.value
        }
        axios.post('https://rahulgputcha.live/mail', request).then((response)=>{
            if(response.data.status===200 && response.data.success===1){
                $("#error-msg").hide();
                $("#success-msg").show();
            }else{
                $("#error-msg").show();
                $("#success-msg").hide();
            }
        });
        

    }

    componentDidMount(){
        $('#form-space').css('opacity', 0);
        

        $(document).on('scroll', ()=>{
            
            if(!this.state.hasAnimationPlayed && ($(document).scrollTop() > $("#contact-me").offset().top-($(window).height()/2)) ){
                $('#form-space').css('opacity', 1).addClass("animate__animated animate__fadeInUp");
                this.setState({
                    hasAnimationPlayed : true
                });
            }
        });
    }

    render(){
        return (
            <section id="contact-me" className="container-fluid text-white p-1 pb-lg-5 px-xxl-5 pt-xxl-5 pt-5">
                <div className="row p-lg-2 pt-lg-5">
                    <div className="col-1 pagebar-region">
                    </div>
                    <div className="col-12 col-xxl-11 p-1 pb-0 pt-xl-5 px-xl-5">
                        <div className="row">
                            <div id="contact-space" className="col-12 col-lg">
                                <div className="title mb-5 pb-4 ps-5 ps-xl-2 ps-xxl-1">
                                    <h2 className="text-uppercase">Contact Me</h2>
                                </div>
                                
                                <p className="h3 text-center text-uppercase text-white pt-5 pb-2">Hope you liked it :)</p>
                                <p className="h5 text-center " style={{color:"rgb(187, 127, 247)"}}>Get in touch with me on</p>
                                <div className="contact-buttons text-center pt-5">
                                    <button onClick={(e)=>{window.location.href="https://www.linkedin.com/in/rahul-putcha/"}} className="linkedin-btn" style={{color:"#006192"}}>Linked <i className="fab fa-linkedin"></i></button>
                                    <button onClick={(e)=>{window.location.href="mailto:rahulgautham95@gmail.com"}} className="gmail-btn" style={{color:"#D44638"}}>Gmail <i className="fas fa-envelope"></i></button><br/>
                                    <button onClick={(e)=>{window.location.href="https://www.youtube.com/channel/UCfZKRgNnGI3RvO3feTz3eYA"}} className="youtube-btn" style={{color:"#D44638"}}>Youtube <i className="fab fa-youtube"></i></button>
                                    <button onClick={(e)=>{window.location.href="https://www.facebook.com/rahul.putcha"}} className="facebook-btn" style={{color:"#4267B2"}}>facebook <i className="fab fa-facebook-f"></i></button>
                                    
                                </div>
                            </div>
                            <div id="form-space" className="col-12 col-lg pt-5 pt-lg-0">
                                <form className="row pt-5 pt-lg-0" onSubmit={this.sendMail}>
                                    <div className="col-12 p-4 p-lg-3 bg-white">
                                        <div className='h4 pt-3'>
                                            <p className="text-dark">Let me know what you are thinking?</p>
                                        </div>
                                        <div className='hr-white bg-dark my-0 mb-4'></div>
                                        <div className='pb-2'>
                                            <div className="label-container"><label htmlFor="contact_name">Name*</label></div>
                                            <input id="contact_name" type="text" name="contact_name" placeholder="Your Name... Ex: Steve Jobs" required/>
                                        </div>
                                        <div className='pb-2'>
                                            <div className="label-container"><label htmlFor="contact_email">Email*</label></div>
                                            <input id="contact_email" type="email" name="contact_email" placeholder="Your Email... Ex: jeff.bezos@amazon.com" required />
                                        </div>
                                        <div className='pb-2'>
                                            <div className="label-container"><label htmlFor="contact_company">Company</label></div>
                                            <input id="contact_company" type="text" name="contact_company" placeholder="Your Company... Ex: Microsoft" />
                                        </div>
                                        <div className='pb-2'>
                                            <div className="label-container"><label htmlFor="contact_number">Contact*</label></div>
                                            <input id="contact_number" type="text" name="contact_number" placeholder="Your Contact No. ... Ex: +1 (732) 123-4567" />
                                        </div>
                                        <div className='pb-2'>
                                            <div className="label-container"><label htmlFor="contact_description">Description*</label></div>
                                            <textarea rows="8" cols="50" id="contact_description" type="text" name="contact_description" placeholder='Anything you want to say? ...'/>
                                        </div>
                                        <div className='py-3 py-lg-2'>
                                            <input className="send-btn" type="submit" value="Send"/>
                                        </div>
                                        <p className="p-2 text-danger" id="error-msg" style={{ display: "none", textAlign: "center", fontSize: "90%"}}>A technical issue occured upon sending the mail! Please try again later...</p>
                                        <p className="p-2 text-success" id="success-msg" style={{ display: "none", textAlign: "center", fontSize: "90%"}}>Your Mail has been sent successfully!! Thank you for reaching out to me!</p>
                                    </div>
                                </form>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
            </section>
        );
    }
    
}

export default ContactMe;
