import React from 'react';
import './Home/Home.css';
import $ from 'jquery';

class Home extends React.Component{
    constructor(props){
        super(props);
        this.scrollToElement = this.scrollToElement.bind(this);
    }
    componentDidCatch(error, errorInfo) {
        //log the error to an error reporting service
        //errorService.log({ error, errorInfo });
    }

    scrollToElement = (elementId)=>{
        $([document.documentElement, document.body]).animate({
            scrollTop: $(elementId).offset().top+10
        }, 500);
    }

    render(){
        return (
            <header id="home" className="container-fluid pt-5 animate__animated animate__fadeIn">
                <div className="row pt-5">
                    <div className="col-1 pagebar-region p-0 text-center">
                        <div>
                            <ul>
                                <li><a onClick={(e)=>{e.preventDefault();this.scrollToElement("#home");}} href="#home">Home</a></li>
                                <li><a onClick={(e)=>{e.preventDefault();this.scrollToElement("#about");}} href="#about">About</a></li>
                                <li><a onClick={(e)=>{e.preventDefault();this.scrollToElement("#my-creations");}} href="#my-creations">My <br/>Creations</a></li>
                                <li><a onClick={(e)=>{e.preventDefault();this.scrollToElement("#my-certifications");}} href="#my-certifications">My <br/>Certifications</a></li>
                                <li><a onClick={(e)=>{e.preventDefault();this.scrollToElement("#contact-me");}} href="#contact-me">Contact Me</a></li>
                            </ul>
                        </div>
                    </div>

                    <div id="introduction" className="col-12 col-xl-6 col-xxl">
                        <div className="row title ms-5 m-xl-0 py-2 py-xl-5">
                            <div style={{overflow:"hidden"}} className="col">
                                <h2 className="animate__animated animate__slideInLeft">Home</h2>
                            </div>
                        </div>
                        <div className="row speech py-0 py-xl-5 ps-5">
                            <div className="col pt-5 pb-0 py-xl-5">
                                <p>Hi I'm</p>
                                <h2>
                                    <span className="first-name">Rahul Gautham</span>
                                    <span className="last-name">Putcha</span>
                                </h2>
                                <p>I'm a Web Developer</p>
                                <button onClick={
                                    (e)=>{
                                        $([document.documentElement, document.body]).animate({
                                            scrollTop: $('#about').offset().top+10
                                        }, 600);
                                    }    
                                } className="my-3 ms-5">Scroll Down</button>
                            </div>
                        </div>
                    </div>
                    
                    <div id="intro-video" className="col-12 col-xl-6 col-xxl pt-1 pt-xl-5 pb-5">
                        <div className="row pt-5">
                            <div className="col-12 pendulum-region">
                                <div className='pendulum'>
                                    {/* Pendulum here */}
                                </div>
                            </div>
                            <div className="col-12 title">
                                <p>PLAY the InTrO</p>
                            </div>
                            <div className="col-12 content pt-5">
                                <iframe title="Intro Video" src="https://www.youtube.com/embed/6P4EKMihOv8"></iframe>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
            </header>
        );
    }
}

export default Home;
